import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import jwtDecode from 'jwt-decode';
import Cookie from 'js-cookie';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import { loginUser, setToken, setUserData } from 'store/actions';
import { Background, List, Loader, LoginForm } from 'shared';

import { LOGIN } from 'api';
import { ROLES } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1550px',
        width: '100%',
        padding: theme.spacing(0, 3),
        margin: theme.spacing(12) + 'px auto'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    listLink: {
        fontSize: '16px',
        '& .MuiTypography-root': {
            fontSize: '16px'
        }
    },
    message: {
        border: '1px solid ' + theme.palette.error.main,
        borderRadius: '30px',
        padding: '10px 20px',
        color: theme.palette.error.main,
        maxWidth: '450px',
        width: '100%',
        margin: '0 auto'
    },
    login: {
        maxWidth: '450px',
        width: '100%'
    },
    input: {
        width: '100%',
        marginLeft: 0
    },
    button: {
        width: '100%'
    },
    aside: {
        marginLeft: 'auto',
        position: 'relative',
        padding: `${theme.spacing(6)}px ${theme.spacing(3)}px ${theme.spacing(3)}px 0`,

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    backgroundTriangle: {
        position: 'absolute',
        zIndex: '-1',
        top: '-1200px',
        left: 'calc(50% - 320px)',
        width: '2000px',
        height: '2000px',

        [theme.breakpoints.down('md')]: {
            left: 'calc(50% - 400px)'
        },
        [theme.breakpoints.down('sm')]: {
            left: '-455px'
        }
    },
    hiddenImage: {
        display: 'none'
    }
}));

const domains = [
    `${process.env.REACT_APP_MY_FLOW_DOMAIN}/api/cookie?jwt_flow=`,
    `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}/api/cookie?jwt_flow=`,
    `${process.env.REACT_APP_TOMORROW_FLOW_DOMAIN}/flowapi/cookie.php?jwt_flow=`
];

const Login = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [login, { loading: mutationLoading, error: mutationError }] = useMutation(LOGIN);
    const user = useSelector((state) => state.auth.user);

    const [token, setTokenState] = useState('');
    const [loginError, setLoginError] = useState('');
    const [open, setOpen] = useState(false);
    const [academieContent, setAcademieContent] = useState(false);
    const [anonymousContent, setAnonymousContent] = useState(false);
    const [afterLogin, setAfterLogin] = useState({});
    const [ssoDone, setSsoDone] = useState([]);

    const quickscanAnonymousPersonId = useSelector((state) => state.auth.quickscanAnonymousPersonId);

    const listItems = [
        t('register.listItem1'),
        t('register.listItem2'),
        t('register.listItem3'),
        t('register.listItem4')
    ];

    const handleLogin = (username, password) => {
        setLoginError('');
        login({
            variables: {
                email: username,
                password: password,
                quickscanAnonymousPersonId: quickscanAnonymousPersonId
            }
        })
            .then((resp) => {
                if (resp.errors) return;
                if (resp.data) {
                    console.log('resp', resp);
                    // ready cookie from that is set from backend
                    const cookie = Cookie.get('jwt_flow');
                    if (cookie) {
                        setTokenState(cookie);
                        // if token exsist open en load SSO images
                        setOpen(true);
                    }

                    // special redirect for quick scan results
                    setAfterLogin({
                        redirect: resp.data.login.redirect,
                        message: t(resp.data.login.message)
                    });
                }
            })
            .catch((err) => {
                const errData = JSON.stringify(err);
                const parsedErrors = JSON.parse(errData);
                const statusCode = parsedErrors.networkError && parsedErrors.networkError.statusCode;
                setLoginError(statusCode);
            });
    };

    useEffect(() => {
        if (token) {
            // if token exsist open en load SSO images
            setOpen(true);
        }
    }, [token]);

    useEffect(() => {
        console.log('domains', domains);
        console.log('ssoDone', ssoDone);

        if (ssoDone.length >= domains.length) {
            if (token && token !== 'deleted') {
                console.log('token', token);
                const decodeToken = jwtDecode(token);
                console.log('decoded', decodeToken);

                dispatch(setToken(token));
                dispatch(setUserData(decodeToken.id));
                dispatch(loginUser(true));

                if (afterLogin.message) {
                    enqueueSnackbar(
                        {
                            variant: 'success',
                            message: afterLogin.message
                        },
                        { persist: true }
                    );
                }

                if (afterLogin.redirect) {
                    history.push(afterLogin.redirect);
                }
            } else {
                console.log('token is deleted?', token);
                history.push('/login');
            }
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssoDone, token]);

    useEffect(() => {
        if (user.id && ssoDone.length >= domains.length && token !== 'deleted') {
            const { roles = [] } = user;
            const { state = {} } = location;

            if (state.loginParam) {
                history.push(state.loginParam);
            } else if (
                roles &&
                !roles.some((i) => i.name === ROLES.USER) &&
                (roles.some((item) => item.name === ROLES.DI_ADVISOR) ||
                    roles.some((item) => item.name === ROLES.CAREER_ADVISOR))
            ) {
                history.push('/mijn-documenten');
            } else {
                history.push('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (location.pathname === '/logout') {
            setTokenState('');
            history.push('/login');
        }

        if (location.search === '?academie') {
            setAcademieContent(true);
        }

        if (location.search === '?AnonymousResults') {
            setAnonymousContent(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleImageLoaded = (domain) => () => {
        setSsoDone((domains) => [...domains, domain]);
    };

    const handleImageErrored = (domain) => () => {
        setSsoDone((domains) => [...domains, domain]);
    };

    const getLoginError = () => {
        switch (loginError) {
            case 401:
                return t('loginForm.notAuthorized');
            case 502:
                return t('loginForm.serverError');
            default:
                t('loginForm.errorMessage');
        }
    };

    return (
        <React.Fragment>
            {/* Set a hidden dialog with images for SSO */}
            <Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} className={classes.hiddenImage}>
                <div>
                    <Loader />
                    {domains.map((domain) => (
                        <img
                            key={domain}
                            src={`${domain}${token}`}
                            alt="sso"
                            onLoad={handleImageLoaded(domain)}
                            onError={handleImageErrored(domain)}
                            className={classes.hiddenImage}
                        />
                    ))}
                </div>
            </Dialog>
            <Grid className={classes.root} container>
                <Background className={classes.backgroundTriangle} />
                <Grid item className={classes.wrapper} xs={12} md={6}>
                    <LoginForm onChange={handleLogin} />
                    {mutationLoading && <Loader />}
                    {mutationError && (
                        <div className={classes.message}>
                            <span>{loginError ? getLoginError() : t('loginForm.errorMessage')}</span>
                        </div>
                    )}
                </Grid>
                <Grid item xs={4} className={classes.aside}>
                    {academieContent && (
                        <List
                            title="Waarom opnieuw inloggen?"
                            text="Je komt net vanuit de academie van jouw corporatie op deze pagina. Hier moet je opnieuw inloggen omdat in je Mijn FLOW-account gegevens staan die alleen voor jou zichtbaar zijn."
                        />
                    )}
                    {anonymousContent && (
                        <List
                            title="Waarom een Mijn FLOW-account aanmaken?"
                            text="Wil je de resultaten van de Quick Scan bewaren? Maak dan gratis een Mijn FLOW-account aan. Je resultaat wordt hier dan automatisch in opgeslagen. Een Mijn FLOW-account biedt ook:"
                            listItems={listItems}
                        />
                    )}
                    {!anonymousContent && !academieContent && (
                        <List title={t('loginForm.listTitle')} listItems={listItems} />
                    )}
                    <span>
                        <br />
                        Werk je niet bij een corporatie? <br />
                        Dan kun je helaas geen account aanmaken.
                    </span>
                    <span>
                        <br />
                        <br />
                        Lukt inloggen niet of heb je vragen?
                        <br />
                        Mail ons op{' '}
                        <a href="mailto:support@flowweb.nl" style={{ color: '#6E3286' }}>
                            support@flowweb.nl
                        </a>
                        .
                    </span>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Login;
